import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import EmptyViewInsertable from '@components/empty-views/EmptyViewInsertable';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as NoDataIcon } from '@icons/empty-states/table-no-data.svg';
const TableNoDataEmptyView = ({ className = undefined, minHeight = undefined, }) => {
    const { t } = useTranslation();
    return (_jsx(EmptyViewInsertable, { className: className, title: t('table.common.no_data'), BodyImgComponent: (_jsx(Icon, { size: [207, 150], keepOriginalColor: true, IconComponent: NoDataIcon })), minHeight: minHeight }));
};
export default TableNoDataEmptyView;
