import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/arrow-left-light.svg';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodyMedium, TextEmphasizedMediumBold, TitleSecondary } from '@components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
const TextMaxWidth = 520;
const Container = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: Math.round(props.theme.spacing_sizes.xs * 1.25),
}));
const BodyContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    gap: props.theme.spacing_sizes.xl,
}));
const MessageTitle = styled(TextEmphasizedMediumBold)(() => ({
    lineHeight: '32px',
}));
const MessageBody = styled(BodyMedium)(() => ({
    lineHeight: '24px',
}));
const MessageColumnContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    width: 'auto',
    maxWidth: TextMaxWidth,
    gap: Math.round(props.theme.spacing_sizes.s * 6),
}));
const MessageContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    width: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const ActionsContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    width: 'auto',
}));
const ArrowIconStyled = styled(ArrowIcon)(() => ({
    transform: 'rotate(180deg)',
}));
const EmptyViewInteractive = ({ className = undefined, title = undefined, BodyImgComponent, messageTitle, messageBody, actionButtonTitle, onActionButtonClick = () => { }, }) => (_jsxs(Container, { className: className, children: [title && (_jsx(TitleSecondary, { children: title })), _jsxs(BodyContainer, { children: [BodyImgComponent, _jsxs(MessageColumnContainer, { children: [_jsxs(MessageContainer, { children: [_jsx(MessageTitle, { children: messageTitle }), _jsx(MessageBody, { children: messageBody })] }), _jsx(ActionsContainer, { children: _jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', onClick: onActionButtonClick, endIcon: ArrowIconStyled, children: actionButtonTitle }) })] })] })] }));
export default EmptyViewInteractive;
